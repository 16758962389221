import './utils/googleTranslateFix'; // This will import the fix for google translate

import { LicenseInfo } from '@mui/x-license';
import { getMuiXKey } from './utils/muiXKey';

LicenseInfo.setLicenseKey(getMuiXKey());

const OAuthEnabled: boolean = import.meta.env.VITE_APP_OAUTH_ENABLED === 'true';

if (OAuthEnabled) {
  import('./indexOauth');
} else {
  import('./indexLegacy');
}
