const MUI_X_PREMIUM_KEY =
  '86a4acd2f5854f7dd865c30fb0ff4ccbTz04OTg3NyxFPTE3NDY2OTI5MzkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';

export const getMuiXKey = () => {
  if (
    'VITE_APP_MUI_X_PREMIUM_LICENSE_KEY' in import.meta.env &&
    typeof import.meta.env.VITE_APP_MUI_X_PREMIUM_LICENSE_KEY === 'string'
  )
    return import.meta.env.VITE_APP_MUI_X_PREMIUM_LICENSE_KEY satisfies string;

  return MUI_X_PREMIUM_KEY;
};
